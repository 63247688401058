import React from 'react';
import VidaluxLogoLight from '../svg/VidaluxLogoLight.png';

const Header2 = () => {
  return (
    <div className='App-header'>
      <img src={VidaluxLogoLight} className='App-logo' alt='logo' />
    </div>
  );
};

export default Header2;
